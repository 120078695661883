import React from "react";
import FeaturedProduct from "../Features/FeaturedProduct";
import useCvProduct from "../../hooks/use-cv-product";

const CvFeed = () => {
  const allProduct = useCvProduct();

  return allProduct.map((node, index) => {
    return <FeaturedProduct key={index} feature={node} />;
  });
};

export default CvFeed;
