import { graphql, useStaticQuery } from "gatsby";

const useCvProduct = () => {
  const {
    allContentfulProduct: { nodes },
  } = useStaticQuery(graphql`
    query cvProductsQuery {
      allContentfulProduct(
        filter: { productCategories: { eq: "cv" } }
        sort: { fields: createdAt, order: ASC }
      ) {
        nodes {
          date
          title
          gatsbyPath(filePath: "/cv/{contentfulProduct.url}")
          introduction
          sku
          price
          description {
            raw
          }
          productCategories
          headerImage {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);

  return nodes;
};

export default useCvProduct;
